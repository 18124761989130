import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import regionsData from "../datas/regions.json";
import "bootstrap/dist/css/bootstrap.min.css";
import BackToTop from "./BackToTop";
import emailjs from "@emailjs/browser";

const TrekDetails = () => {
  const { slug, trekSlug } = useParams();

  // 🔁 HOOKS
  const [stopAtFooter, setStopAtFooter] = useState(false);
  const inquiryRef = useRef(null);
  const formRef = useRef();
  const [formErrors, setFormErrors] = useState({});
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector("footer");
      const form = inquiryRef.current;

      if (!footer || !form) return;

      const footerTop = footer.getBoundingClientRect().top;
      const formHeight = form.offsetHeight;

      if (footerTop < formHeight + 160) {
        setStopAtFooter(true);
      } else {
        setStopAtFooter(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const region = regionsData.find((region) => region.slug === slug);
  const trek = region?.treks.find((trek) => trek.slug === trekSlug);
  if (!region || !trek) return <Navigate to="/not-found" replace />;

  // 📩 Handle form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSuccessMsg("");

    const data = {
      full_name: formRef.current.full_name.value.trim(),
      email: formRef.current.email.value.trim(),
      phone_number: formRef.current.phone_number.value.trim(),
      message: formRef.current.message.value.trim(),
    };

    const newErrors = {};

    if (!data.full_name || data.full_name.length < 3 || data.full_name.length > 50) {
      newErrors.full_name = "Le nom doit contenir entre 3 et 50 caractères.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!data.email || !emailRegex.test(data.email)) {
      newErrors.email = "Veuillez entrer une adresse e-mail valide.";
    }

    if (!data.message || data.message.length < 10 || data.message.length > 420) {
      newErrors.message = "Le message doit contenir entre 10 et 420 caractères.";
    }

    if (Object.keys(newErrors).length > 0) {
      setFormErrors(newErrors);
      return;
    }

    setFormErrors({});

    emailjs.sendForm(
      "service_up9qhfe",
      "template_jf2x8rk",
      formRef.current,
      "QwaW4cD1EZG0un1pM"
    ).then(() => {
      setSuccessMsg("Votre message a bien été envoyé !");
      formRef.current.reset();
    }).catch(() => {
      setFormErrors({ form: "Erreur lors de l'envoi. Veuillez réessayer." });
    });
  };

  return (
    <div className="trek-detail-wrapper">
      <nav aria-label="breadcrumb" className="breadcrumb_container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item"><Link to={`/region/${slug}`}>{region.name}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{trek.name}</li>
        </ol>
      </nav>

      <div className="trek-layout">
        {/* LEFT SIDE */}
        <div className="trek-content">
          <h1 className="mb-3 trekBigTitle">{trek.name}</h1>
          <p className="text-muted">Duration : {trek.duration} days</p>

          {trek["thumbnail-1"] && trek["thumbnail-2"] && trek["thumbnail-3"] && (
            <div className="angled-gallery">
              <div className="img-container first">
                <img src={trek["thumbnail-1"]} alt="Trek 1" />
              </div>
              <div className="img-container middle">
                <img src={trek["thumbnail-2"]} alt="Trek 2" />
              </div>
              <div className="img-container last">
                <img src={trek["thumbnail-3"]} alt="Trek 3" />
              </div>
            </div>
          )}

          <div className="description-trek">
            {typeof trek.full_description === "object"
              ? Object.values(trek.full_description).map((p, i) => <p key={i}>{p}</p>)
              : <p>{trek.full_description}</p>}
          </div>

          <div className="mb-5">
            <h4 className="trekBigTitle">Facts of the trek</h4>
            <table className="table trek-info-table">
              <tbody>
                <tr>
                  <th>Grade</th>
                  <td>
                    <div className="difficulty-icons">
                      {[1, 2, 3, 4, 5].map((level) => (
                        <span key={level} className={`boot-icon ${trek.facts.difficulty_level >= level ? 'active' : ''}`}>
                          🥾
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
                <tr><th>Best Season</th><td>{trek.facts.best_season}</td></tr>
                <tr><th>Type of Trek</th><td>{trek.facts.trek_type}</td></tr>
                <tr><th>Duration</th><td>{trek.facts.duration}</td></tr>
                <tr><th>Max elevation</th><td>{trek.facts.max_elevation}</td></tr>
              </tbody>
            </table>
          </div>

          <div className="mb-4">
            <h4 className="trekBigTitle">Trip Highlights</h4>
            <ul>
              {trek.trip_highlights.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          <div className="mb-5">
            <h4 className="mb-4 trekBigTitle">Itinerary</h4>
            <div className="timeline-wrapper">
              <ul className="sessions">
                {trek.itinerary.map((day) => (
                  <li key={day.day}>
                    <div className="time">Day {day.day}</div>
                    <p>{day.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {trek.itinerary_outline?.length > 0 && (
            <div className="mb-4">
              <h4>Itinerary Outline</h4>
              {trek.itinerary_outline.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </div>
          )}
        </div>

        {/* RIGHT SIDE: Quick Inquiry */}
        <div className="inquiry-form-wrapper">
          <div
            className={`inquiry-form fixed-inquiry ${stopAtFooter ? "stop" : ""}`}
            ref={inquiryRef}
          >


            {successMsg && <p style={{ color: "green" }}>{successMsg}</p>}
            {formErrors.form && <p style={{ color: "red" }}>{formErrors.form}</p>}

           

            <div className={`inquiry-form fixed-inquiry ${stopAtFooter ? "stop" : ""}`} ref={inquiryRef}>
  
  <h4 style={{ textAlign: "center", marginBottom: "20px" }}>Quick Inquiry</h4>

  {successMsg && <p style={{ color: "green", textAlign: "center" }}>{successMsg}</p>}
  {formErrors.form && <p style={{ color: "red", textAlign: "center" }}>{formErrors.form}</p>}

  <form ref={formRef} className="form-wrapper" onSubmit={handleFormSubmit}>
    {/* Full Name */}
    <div className="form-card">
      <input className="form-input" type="text" name="full_name" required />
      <label className="form-label" htmlFor="full_name">Full Name</label>
      {formErrors.full_name && <p style={{ color: "red" }}>{formErrors.full_name}</p>}
    </div>

    {/* Email */}
    <div className="form-card">
      <input className="form-input" name="email" type="email" required />
      <label className="form-label" htmlFor="email">Email</label>
      {formErrors.email && <p style={{ color: "red" }}>{formErrors.email}</p>}
    </div>

    {/* Phone Number */}
    <div className="form-card">
      <input className="form-input" name="phone_number" type="text" required="not"/>
      <label className="form-label" htmlFor="phone_number">Phone Number</label>
    </div>

    {/* Message */}
    <div className="form-card">
      <textarea
        className="form-textarea"
        maxLength="420"
        rows="3"
        name="message"
        required
      ></textarea>
      <label className="form-textarea-label" htmlFor="message">Message</label>
      {formErrors.message && <p style={{ color: "red" }}>{formErrors.message}</p>}
    </div>

    {/* Submit Button */}
    <div className="btn-wrap">
      <button type="submit">Send</button>
    </div>
  </form>
</div>

            
          </div>
        </div>
      </div>

      <BackToTop />
    </div>
  );
};

export default TrekDetails;
